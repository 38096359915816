/* NOTE: Please add/update new styles in {NAME}.styles.js closed to {NAME}.jsx
  using:
  - styled-components instead of plain css
  - camel case notation for file names
  for consistency
*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
