/* NOTE: Please add/update new styles in {NAME}.styles.js closed to {NAME}.jsx
  using:
  - styled-components instead of plain css
  - camel case notation for file names
  for consistency
*/
.draftNoSelected > div > div > div {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}
